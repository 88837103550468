.chef-main-container{
    width: 80%;
    margin: 3rem auto;
}

.chef-list-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.chef-list-container a{
    text-decoration: none;
    color: #c19434;
}
