.create_post {
    display: flex;
    justify-content: center;
    width: 100vw;
  form {
    h1 {
      color: #535353;
      span {
        font-size: 14px;
        font-weight: lighter;
        margin-left: 3rem;
        color: red;
      }
    }
    box-shadow: 0px 15px 20px #cdddb5;
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: #ebebeb;
    margin-bottom: 2rem;
    button {
      background-color: #c19434;
      font-size: 1rem;
      color: whitesmoke;
      border: none;
    }
    input,
    textarea,
    button,
    select {
      margin: 1rem;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      padding: 0.5rem;
    }
    select {
        height: 1.9rem;
    }
    textarea {
        max-width: 67.7vw;
    }
    input:focus, textarea:focus, select:focus{
        outline-color: #c19434 ;
    }
  }
}
