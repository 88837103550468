.single-post {
  box-shadow: 0px 15px 20px #cdddb5;
  width: 70%;
  background-color: #ebebeb;
  margin: 2rem auto;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  .recipe-img {
    width: 40%;
    box-shadow: 0px 8px 10px #979797;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .recipe-content {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-weight: bold;
    }
  }
}
.home-redirect {
  width: 70%;
  margin-bottom: 2rem;
  height: 2rem;
  border: none;
  background-color: #c19434;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.4rem;
  color: white;
}
