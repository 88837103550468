.chef-container{
    width: 100%;
    margin: 3rem auto;
}

.chef{
    margin: 3rem ;
    padding: 2rem;
    background-color: #cdddb5;
}

