*{
    box-sizing: border-box;
}



.signup-container{
    display: flex;
    flex-direction: column;
}

.signup{
    display: flex;
    margin-top: 8%;
}

.signup-form{
    width: 40%;
    height: 70%;
    margin: 2rem;
    border: 1px solid #c19434;
    border-radius: 5rem;
}

.signup-image{
    width: 50%;
}

img{
    width: 80%;
    height: 80%;
}


.signup-form form label{
    display: block;
}

.signup-form form input{
    display: block;
    width: 80%;
    margin: 2rem auto;
}

.signup-container a{
    text-decoration: none;
    color: #c19434;
}

.signup-container a:hover{
    text-decoration: underline;
}

@media (max-width: 800px)
{
    .signup-form{
        width: 80%;
        height: 70%;
        margin: 2rem;
        
    }

    .signup-image{
        display: none;
    }
}