*{
  box-sizing: border-box;
}


.App {
  text-align: center;
  background: #f8f8f8;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  width: 100%;
  max-width: 100%;
}

.App-link {
  color: #cdddb5;
}

.navbar {
  border-bottom: 1px solid #eee;
  width: 100%;
  min-height: 80px;
  max-width: 100%;
  box-shadow: 2px 2px #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  border-bottom: 1px solid grey;

}

@media(max-width:600px){
  .navbar{
      flex-direction: column;
      width: 100%;
  }  
}



.left-nav {
  margin-left: 45px;
  font-size: 20px;
  /* padding-top: 15px; */
}

.red {
  color: #c19434;
}

.green {
  color: #cdddb5;
}
.right-nav {
  width: 70%;
  margin-right: 15px;
  float: right;
  /* max-width: 50%; */
  /* padding-top: 20px; */
}



@media(max-width:500px){
  .right-nav{
    width: 100%;
  }
  
  .right-nav a{
    padding: 0;
    float: none;
  }

  .left-nav{
    width: 100%;
    border-color: #c19434;
    margin-left: 0;
}  
}

.right-nav a {
  margin-right: 10px;
  text-decoration: none;
  color: #101010;
  font-size: 16px;
  padding: 1rem;
}

@media(max-width:600px){
  
  .right-nav a{
    padding: 0.5rem;
  }
}

.right-nav a:hover {
  border-bottom: 1px solid #cdddb5;
}

.post-main-container {

  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  height: auto;
}

.posts-list-container {
  width: 80%;
  margin: 5% auto;
  height: auto;
  flex-wrap: wrap;
  display: flex;
}


.posts-list-container a {
  width: 30%;
  text-decoration: none;
  margin: 1rem;
}

@media(max-width:900px){
  .posts-list-container a {
      width: 40%;
      margin: 3rem auto;
  }  
}

@media(max-width:600px){
  .posts-list-container a {
      width: 70%;
      margin: 3rem auto;
  }  
}

.list-card {
  border-radius: 7px;
  background: #fff;
  height: auto;
  max-height: auto;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  border-radius: 10%;
  box-shadow: 5px 5px 5px 5px #cdddb5;
}

.img-container {
  width: 100%;
  max-width: 100%;
  height: 150px;
  border-radius: 10%;
  border: 1px solid #fff;
}

.img-container img {
  width: 100%;
  max-height: 100%;
  border-radius: 15% 15% 0 0;
  border: 1px solid #fff;
}

.post-info-container {
  width: 100%;
  max-width: 100%;
  height: auto;
  background: #c19434;
  text-align: left;
  border-radius:0 0 10% 10%;
}

.title {
  color: #fff;
  font-size: 20px;
  padding-left: 15px;

}

.others,
.cook-time {
  font-size: 14px;
  padding-left: 15px;
}
.others {
  color: #fff;
}

.cook-time {
  color: #fff;


}

